import React from "react";
import Cabecera from "../../components/Cabecera";
import Trayectoria from "../../components/Trayectoria";
import Certificados from "../../components/Certificados";
import Testimonios from "../../components/Testimonios";
import ContactoForm from "../../components/ContactoForm";
import Footer from "../../components/Footer";
import SobreMi from "../../components/SobreMi";
import Gallery from "../../components/Gallery";

export default function Home() {
  return (
    <>
      <Cabecera />
      <SobreMi />
      <Gallery />
      <Trayectoria />
      <Certificados />
      <Testimonios />
      <ContactoForm />
      <Footer />
    </>
  );
}
