import React from "react";

import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import gallery1 from "./../assets/gallery1.jpg";
import gallery2 from "./../assets/gallery2.jpg";
import gallery3 from "./../assets/gallery3.jpg";
import gallery4 from "./../assets/gallery4.jpg";
import gallery5 from "./../assets/gallery5.jpg";
import gallery6 from "./../assets/gallery6.jpg";

import gallery7 from "./../assets/gallery7.jpg";
import gallery8 from "./../assets/gallery8.jpg";

import gallery9 from "./../assets/gallery9.jpg";

import gallery10 from "./../assets/gallery10.jpg";

export default function Example() {
  const photos1 = [
    {
      image: gallery1,
    },
    {
      image: gallery6,
    },
    {
      image: gallery2,
    },
    {
      image: gallery7,
    },
    {
      image: gallery3,
    },
  ];
  const photos2 = [
    {
      image: gallery8,
    },
    {
      image: gallery4,
    },
    {
      image: gallery9,
    },
    {
      image: gallery5,
    },
    {
      image: gallery10,
    },
  ];
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };
  return (
    <>
      <Slider {...settings}>
        <div>
          <div className="bg-white">
            <div className="mx-auto py-12 px-4 max-w-7xl sm:px-6 lg:px-8 lg:py-12">
              <div className="space-y-12">
                <ul className="space-y-12 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 sm:space-y-0 lg:grid-cols-5 lg:gap-x-8">
                  {photos1.map((photo, index) => (
                    <li key={index}>
                      <div className="space-y-4">
                        <div className="aspect-w-3 aspect-h-2">
                          <img
                            className="object-cover shadow-lg rounded-lg"
                            src={photo.image}
                            alt=""
                          />
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div className="bg-white">
            <div className="mx-auto py-12 px-4 max-w-7xl sm:px-6 lg:px-8 lg:py-12">
              <div className="space-y-12">
                <ul className="space-y-12 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 sm:space-y-0 lg:grid-cols-5 lg:gap-x-8">
                  {photos2.map((photo, index) => (
                    <li key={index}>
                      <div className="space-y-4">
                        <div className="aspect-w-3 aspect-h-2">
                          <img
                            className="object-cover shadow-lg rounded-lg"
                            src={photo.image}
                            alt=""
                          />
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </Slider>
    </>
  );
}
