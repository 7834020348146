import React from "react";
import collage from "./../assets/collage.jpg";

const ContactoForm = () => {
  return (
    <section class="text-gray-600 body-font relative" id="contacto">
      <div class="container px-5 py-24 mx-auto flex sm:flex-nowrap flex-wrap">
        <div class="lg:w-2/3 md:w-1/2 bg-gray-300 rounded-lg overflow-hidden sm:mr-10 p-10 flex items-end justify-start relative">
          {/* TODO ajustar la img */}
          <img src={collage} alt="" className=""></img>
        </div>
        <div class="lg:w-1/3 md:w-1/2 bg-white flex flex-col md:ml-auto w-full md:py-8 mt-8 md:mt-0">
          <h2 className=" text-center mb-1 text-3xl font-extrabold tracking-tight sm:text-5xl">
            Contacto
          </h2>
          <div className="relative max-w-md mx-auto py-12 px-4 space-y-6 sm:max-w-3xl sm:py-16 sm:px-6 lg:max-w-none lg:p-0 lg:col-start-4 lg:col-span-6">
            <h1 className="text-sm tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-lg text-center ">
              <span className="block xl:inline">
                Barbara Cecilia Zamora Tapia
              </span>{" "}
              <br />
              {/* TODO hacer mas chica la letra */}
              <span className="block text-indigo-600 text-sm xl:inline">
                Terapeuta Ocupacional y{" "}
              </span>
              {/* TODO buscar celeste correcto*/}
              <span className="block text-blue-400 text-sm xl:inline">
                Profesora de Natación
              </span>
              <br/>
              Bczamora96@gmail.com
              <br/>
              +56966088903
            </h1>
          </div>
          <div class="relative mb-4">
            <label for="name" class="leading-7 text-sm text-gray-600">
              Nombre
            </label>
            <input
              type="text"
              id="name"
              name="name"
              class="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
            />
          </div>
          <div class="relative mb-4">
            <label for="email" class="leading-7 text-sm text-gray-600">
              Correo
            </label>
            <input
              type="email"
              id="email"
              name="email"
              class="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
            />
          </div>
          <div class="relative mb-4">
            <label for="message" class="leading-7 text-sm text-gray-600">
              Mensaje
            </label>
            <textarea
              id="message"
              name="message"
              class="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 h-32 text-base outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"
            ></textarea>
          </div>
          <button class="text-white bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded text-lg">
            Enviar
          </button>
        </div>
      </div>
    </section>
  );
};

export default ContactoForm;
