import certificado1 from "./../assets/certificado1.png";
import certificado2 from "./../assets/certificado2.png";
import certificado3 from "./../assets/certificado3.png";
import certificado4 from "./../assets/certificado4.png";
import certificado5 from "./../assets/certificado5.png";
import certificado6 from "./../assets/certificado6.png";
import certificado7 from "./../assets/certificado7.png";
import certificado8 from "./../assets/certificado8.png";
import certificado9 from "./../assets/certificado9.png";
import certificado10 from "./../assets/certificado10.png";
import certificado11 from "./../assets/certificado11.png";
import certificado12 from "./../assets/certificado12.png";

const certificados = [
  {
    name: "Condición del Espectro Autista y Apoyos Funcionales desde una mirada respetuosa",
    role: "22 Agosto 2020",
    imageUrl: certificado9,
  },
  {
    name: "Estrategias de evaluación para padres y cuidadores (as)",
    role: "6 de Junio 2020",
    imageUrl: certificado8,
  },
  {
    name: "Certificación de Aquafitness - Nivel Básico",
    role: "Octubre 2020",
    imageUrl: certificado11,
  },
  {
    name: "Principales estrategias de identificación e intervención en niños y niñas con discapacidad visual",
    role: "Octubre 2020 a Noviembre 2020",
    imageUrl: certificado10,
  },
  {
    name: "Certificación Tutor Sombra",
    role: "Octubre  2020 a Diciembre 2020",
    imageUrl: certificado7,
  },
  {
    name: "Certificación de Instructor de Aquafitness Método APT - Aqua Power Training",
    role: "Diciembre 2020 a Febrero 2021",
    imageUrl: certificado12,
  },

  {
    name: "Taller de Padres Baby Signs",
    role: "Enero 2021",
    imageUrl: certificado2,
  },
  {
    name: "Diplomado en Inclusión Educativa",
    role: "Enero 2021 a Abril 2021",
    imageUrl: certificado6,
  },
  {
    name: "Evaluación del Procesamiento Sensorial: Uso y Admisnitración del Perfil Senosrial 2 Winnie Dunn",
    role: "13 y 14 de abril 2021",
    imageUrl: certificado1,
  },
  {
    name: "Introducción a la Medicina del Estilo de Vida",
    role: "3 de Octubre 2021 hasta 20 de Noviembre 2021",
    imageUrl: certificado4,
  },

  {
    name: "Comunicación en Lengua de Señas Chilena, Nivel Básico A1",
    role: "Enero a Febrero 2022",
    imageUrl: certificado3,
  },
  {
    name: "Comunicación para Todos",
    role: "Noviembre 2021 a Marzo 2022",
    imageUrl: certificado5,
  },
];

export default function Certificados() {
  return (
    <div className="bg-white" id="certificados">
      <div className="mx-auto py-12 px-4 max-w-7xl sm:px-6 lg:px-8 lg:py-24">
        <div className="space-y-12">
          <div className="space-y-5 sm:space-y-4 md:max-w-xl lg:max-w-3xl xl:max-w-none">
            <h2 className=" text-center text-3xl font-extrabold tracking-tight sm:text-5xl">
              Certificados de Cursos
            </h2>
          </div>
          <div
            role="list"
            className="space-y-12 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 sm:space-y-0 lg:grid-cols-3 lg:gap-x-8"
          >
            {certificados.map((certificado) => (
              <div key={certificado.name}>
                <div className="space-y-4">
                  <div className="aspect-w-3 aspect-h-2">
                    <img
                      className="object-cover shadow-lg rounded-lg"
                      src={certificado.imageUrl}
                      alt=""
                    />
                  </div>

                  <div className="space-y-2">
                    <div className="text-lg leading-6 font-medium space-y-1">
                      <h3>{certificado.name}</h3>
                      <p className="text-indigo-600">{certificado.role}</p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
