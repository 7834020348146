import React from 'react'

const LayoutMain = (props) => {
  return (
    <div>
      {/* <NavBar/> */}
        {props.children}
      {/* <Footer/> */}
    </div>
  )
}

export default LayoutMain
