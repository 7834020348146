//Layouts
import LayoutMain from '../layouts/LayoutMain'

//Pages
import Home from '../pages/Home'




const routes = [
  {
    path: "/",
    layout:LayoutMain,
    component: Home,
    exact: true
  },
  
  {
    layout:LayoutMain,
    component: Home
  }
]

export default routes