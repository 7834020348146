import barbara2 from "./../assets/barbara2.jpg";

export default function SobreMi() {
  return (
    <div className="bg-white overflow-hidden" id="sobremi">
      <div className="relative max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
        <div className="hidden lg:block bg-gray-50 absolute top-0 bottom-0 left-3/4 w-screen" />
        <div className="mx-auto text-base max-w-prose lg:grid lg:grid-cols-2 lg:gap-8 lg:max-w-none">
          <div>
            {/* <h2 className="text-base text-indigo-600 font-semibold tracking-wide uppercase">Case Study</h2> */}
            <h3 className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Sobre Mi
            </h3>
          </div>
        </div>
        <div className="mt-8 lg:grid lg:grid-cols-2 lg:gap-8">
          <div className="relative lg:row-start-1 lg:col-start-2">
            <svg
              className="hidden lg:block absolute top-0 right-0 -mt-20 -mr-20"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384"
              aria-hidden="true"
            >
              <defs>
                <pattern
                  id="de316486-4a29-4312-bdfc-fbce2132a2c1"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x={0}
                    y={0}
                    width={4}
                    height={4}
                    className="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width={404}
                height={384}
                fill="url(#de316486-4a29-4312-bdfc-fbce2132a2c1)"
              />
            </svg>
            <div className="relative text-base mx-auto max-w-prose lg:max-w-none">
              <figure>
                <div className="aspect-w-12 aspect-h-7 lg:aspect-none">
                  <img
                    className="rounded-lg shadow-lg object-cover object-center"
                    src={barbara2}
                    alt="Whitney leaning against a railing on a downtown street"
                    width={1184}
                    height={1376}
                  />
                </div>
              </figure>
            </div>
          </div>
          <div className="mt-8 lg:mt-0">
            <div className="text-base max-w-prose mx-auto lg:max-w-none">
              <p className="text-lg text-gray-500 text-justify">
                Hola mi nombre es Barbara Zamora, soy Terapeuta Ocupacional con
                mención en Pediatría, egresada de la Universidad de los Andes
                (2019), diplomada en Educación Inclusiva y certificada como
                tutor sombra.
              </p>
            </div>
            <div className="mt-5 prose prose-indigo text-gray-500 mx-auto lg:max-w-none lg:row-start-1 lg:col-start-1">
              <p className="text-lg text-gray-500 text-justify">
                Mi experiencia data desde el 2019 en la atención a niños de los
                0 a 12 años en el área de salud privada a través de atenciones
                domiciliarias dentro de la Región Metropolitana, en colegio
                internacional, en diversos Centros especializados en primera
                infancia, abordando Trastornos del Espectro Autista, Trastornos
                del Neurodesarrollo, Trastornos de la Conducta infantil –
                juvenil y Trastornos en el procesamiento sensorial.
              </p>
            </div>
            <div className="mt-5 prose prose-indigo text-gray-500 mx-auto lg:max-w-none lg:row-start-1 lg:col-start-1">
              <p className="text-lg text-gray-500 text-justify">
                Así como soy Terapeuta Ocupacional, soy nadadora de Natación
                Clásica. Esto surge a los 3 años cuando aprendí a nadar gracias
                a mi madre que me inscribió en cursos de verano hasta los 6
                años, luego conocí lo que es formar parte de una rama de
                natación con niños de mi edad. A los 10 años tomé la decisión
                ser parte de un equipo y empecé a competir en diferentes
                campeonatos a lo largo del país. También tuve la oportunidad de
                vivir en Alemania, formar parte de otro equipo, aprender otras
                formas de entrenamientos y piscinas. Ya en la Universidad fui
                parte de la selección de natación y seguí compitiendo
                representando a mi Universidad en campeonatos nacionales. Ahora
                ya como nadadora master he podido seguir participando de lo que
                es la natación competitiva junto a mi madre, amigos que han
                perdurado a lo largo de los años y la oportunidad de ir a
                competir fuera del país.
              </p>
            </div>
            <div className="mt-5 prose prose-indigo text-gray-500 mx-auto lg:max-w-none lg:row-start-1 lg:col-start-1">
              <p className="text-lg text-gray-500 text-justify">
                Gracias a la natación he podido adquirir herramientas que me han
                ayudado a mi crecimiento personal y profesional. Todo comenzó
                como una necesidad de seguridad y sobrevivencia que luego se
                convirtió parte de mí y me llevo el querer compartirlo con otros
                convirtiéndome lentamente desde la experiencia en profesora de
                natación certificada por la Asociación Americana de Coaches de
                Natación, SwimAmerica Program.
              </p>
            </div>

            <div className="mt-5 prose prose-indigo text-gray-500 mx-auto lg:max-w-none lg:row-start-1 lg:col-start-1">
              <p className="text-lg text-gray-500 text-justify">
                Mi carrera me ha permitido ocupar mis hobbies e intereses en el
                trabajo con los niños pudiendo entregarles herramientas que les
                permitirán experimentar y conocer desde pequeños su mundo,
                crecer y desenvolverse de la mejor manera. Siento que toda
                experiencia genera un aprendizaje y aporte tanto para los niños
                como a nosotros que trabajamos con ellos.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
