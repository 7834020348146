import React, { useEffect, useState } from "react";
import axios from "axios";
import TestimonioForm from "./TestimonioForm";

export default function Testimonios() {
  const [comments, setComments] = useState([]);

  useEffect(() => {
    axios
      .get("https://backend-bzt-production.up.railway.app/api/comment/findAll/bczt96")
      .then((res) => {
        setComments(res.data);
        console.log(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <section class="text-gray-600 body-font" id="testimonios">
      <div class="container mg:px-36 px-5 py-24 mx-auto">
        <h1 class="text-3xl font-medium title-font text-gray-900 mb-12 text-center">
          Testimonios
        </h1>
        <div class="flex flex-wrap -m-4">
          {comments.map((c) => {
            return (
              <div class="p-4 md:w-1/2 w-full">
                <div class="h-full bg-indigo-200 p-8 rounded-2xl">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    class="block w-5 h-5 text-indigo-400 mb-4"
                    viewBox="0 0 975.036 975.036"
                  >
                    <path d="M925.036 57.197h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.399 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l36 76c11.6 24.399 40.3 35.1 65.1 24.399 66.2-28.6 122.101-64.8 167.7-108.8 55.601-53.7 93.7-114.3 114.3-181.9 20.601-67.6 30.9-159.8 30.9-276.8v-239c0-27.599-22.401-50-50-50zM106.036 913.497c65.4-28.5 121-64.699 166.9-108.6 56.1-53.7 94.4-114.1 115-181.2 20.6-67.1 30.899-159.6 30.899-277.5v-239c0-27.6-22.399-50-50-50h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.4 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l35.9 75.8c11.601 24.399 40.501 35.2 65.301 24.399z"></path>
                  </svg>
                  <p class="leading-relaxed mb-6">{c.comment}</p>
                  <div href="/" class="inline-flex items-center">
                    <span class="flex-grow flex flex-col pl-4">
                      <span class="title-font font-medium text-gray-900">
                        {c.name}
                      </span>
                      {c.rate === "5" ? (
                        <span class="text-gray-500 text-sm">⭐⭐⭐⭐⭐</span>
                      ) : c.rate === "4" ? (
                        <span class="text-gray-500 text-sm">⭐⭐⭐⭐ </span>
                      ) : c.rate === "3" ? (
                        <span class="text-gray-500 text-sm">⭐⭐⭐ </span>
                      ) : c.rate === "2" ? (
                        <span class="text-gray-500 text-sm">⭐⭐ </span>
                      ) : (
                        <span class="text-gray-500 text-sm">⭐ </span>
                      )}
                    </span>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      {/* <TestimonialForm2/> */}
      <TestimonioForm  />
    </section>
  );
}
